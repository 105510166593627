var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "480",
            "max-height": "400",
            persistent: "",
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "overflow-hidden" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 ml-4" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                    },
                    [_vm._v(_vm._s(_vm.$t("threeHrNoticeTicket")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "col-2 my-auto",
                      attrs: { "aria-label": "cross", icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [_c("v-divider")],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-4",
                  class: "mb-0",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.reasonList,
                      "menu-props": { bottom: true, offsetY: true },
                      outlined: "",
                      dense: "",
                      required: "",
                      "error-messages": _vm.reasonErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.reason.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.reason.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(" *"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.$t("reason")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.getReasonText(data.item)) + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "selection",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _vm._v(" " + _vm._s(_vm.getReasonText(item)) + " "),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.reason,
                      callback: function ($$v) {
                        _vm.reason = $$v
                      },
                      expression: "reason",
                    },
                  }),
                ],
                1
              ),
              _vm.reason == "THERE IS CLEAR EVIDENCE OF AN UNMARKED UTILITY"
                ? _c(
                    "v-row",
                    {
                      staticClass: "mx-4",
                      class: "mb-0",
                      attrs: { "no-gutters": "", justify: "center" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.typesOfMarksList,
                          "menu-props": { bottom: true, offsetY: true },
                          outlined: "",
                          dense: "",
                          required: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm.reason ===
                                  "THERE IS CLEAR EVIDENCE OF AN UNMARKED UTILITY"
                                    ? _c("span", { staticClass: "red--text" }, [
                                        _vm._v(" *"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("typesOfMarks")) + " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "item",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getReasonText(data.item)) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "selection",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " + _vm._s(_vm.getReasonText(item)) + " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2502009863
                        ),
                        model: {
                          value: _vm.typesOfMarks,
                          callback: function ($$v) {
                            _vm.typesOfMarks = $$v
                          },
                          expression: "typesOfMarks",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.reason == "THERE IS CLEAR EVIDENCE OF AN UNMARKED UTILITY"
                ? _c(
                    "v-row",
                    {
                      staticClass: "mx-4",
                      class: "mb-0",
                      attrs: { "no-gutters": "", justify: "center" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.facilityTypesList,
                          "menu-props": { bottom: true, offsetY: true },
                          outlined: "",
                          multiple: "",
                          chips: "",
                          dense: "",
                          required: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", { staticClass: "red--text" }, [
                                    _vm._v(" *"),
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("facilityTypes")) + " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "item",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getReasonText(data.item)) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "selection",
                              fn: function (ref) {
                                var attrs = ref.attrs
                                var item = ref.item
                                var parent = ref.parent
                                var selected = ref.selected
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._b(
                                      {
                                        attrs: {
                                          size: "small",
                                          density: "compact",
                                          "input-value": selected,
                                        },
                                      },
                                      "v-chip",
                                      attrs,
                                      false
                                    ),
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.getReasonText(item)) +
                                          " "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return parent.selectItem(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-close-circle ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1581802719
                        ),
                        model: {
                          value: _vm.facilityTypes,
                          callback: function ($$v) {
                            _vm.facilityTypes = $$v
                          },
                          expression: "facilityTypes",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.reason == "DISPUTED OR NO RESPONSE"
                ? _c(
                    "v-row",
                    {
                      staticClass: "mx-4",
                      class: "mb-0",
                      attrs: { "no-gutters": "", justify: "center" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.responseCodeList,
                          "menu-props": { bottom: true, offsetY: true },
                          outlined: "",
                          multiple: "",
                          dense: "",
                          chips: "",
                          required: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm.reason === "DISPUTED OR NO RESPONSE"
                                    ? _c("span", { staticClass: "red--text" }, [
                                        _vm._v(" *"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("responseCodes")) + " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "item",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getReasonText(data.item)) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "selection",
                              fn: function (ref) {
                                var attrs = ref.attrs
                                var item = ref.item
                                var parent = ref.parent
                                var selected = ref.selected
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._b(
                                      {
                                        attrs: {
                                          size: "small",
                                          density: "compact",
                                          "input-value": selected,
                                        },
                                      },
                                      "v-chip",
                                      attrs,
                                      false
                                    ),
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "chip-content" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.getReasonText(item))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return parent.selectItem(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-close-circle ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4032527504
                        ),
                        model: {
                          value: _vm.responseCodes,
                          callback: function ($$v) {
                            _vm.responseCodes = $$v
                          },
                          expression: "responseCodes",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.reason
                ? _c(
                    "v-row",
                    {
                      staticClass: "mx-4",
                      class: "mb-0",
                      attrs: { "no-gutters": "", justify: "center" },
                    },
                    [
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          required: "",
                          rows: "2",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("description")) + " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2017196193
                        ),
                        model: {
                          value: _vm.description,
                          callback: function ($$v) {
                            _vm.description = $$v
                          },
                          expression: "description",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.reason
                ? _c(
                    "v-row",
                    {
                      staticClass: "mx-4",
                      attrs: { "no-gutters": "", justify: "center" },
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.membersToNotifyList,
                          "menu-props": { bottom: true, offsetY: true },
                          label: _vm.$t("membersToNotify"),
                          outlined: "",
                          dense: "",
                          required: "",
                          multiple: "",
                          "item-text": "mbcode_name",
                          "item-value": "mbcode",
                          "search-input": _vm.searchMembers,
                        },
                        on: {
                          input: function ($event) {
                            _vm.searchMembers = null
                          },
                          "update:searchInput": function ($event) {
                            _vm.searchMembers = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.searchMembers = $event
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend-item",
                              fn: function () {
                                return [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-inner-icon": "mdi-magnify",
                                          label: _vm.$t(
                                            "searchMembersToNotify"
                                          ),
                                          "single-line": "",
                                          "hide-details": "",
                                          flat: "",
                                          dense: "",
                                          variant: "underlined",
                                          "clear-icon": "mdi-close-circle",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.searchMembers,
                                          callback: function ($$v) {
                                            _vm.searchMembers = $$v
                                          },
                                          expression: "searchMembers",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "selection",
                              fn: function (ref) {
                                var attrs = ref.attrs
                                var item = ref.item
                                var parent = ref.parent
                                var selected = ref.selected
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._b(
                                      {
                                        attrs: {
                                          size: "small",
                                          density: "compact",
                                          "input-value": selected,
                                        },
                                      },
                                      "v-chip",
                                      attrs,
                                      false
                                    ),
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "pr-2 chip-content" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.mbcode_name) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return parent.selectItem(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-close-circle ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1058750345
                        ),
                        model: {
                          value: _vm.selectedMembersToNotify,
                          callback: function ($$v) {
                            _vm.selectedMembersToNotify = $$v
                          },
                          expression: "selectedMembersToNotify",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.reason
                ? _c(
                    "v-row",
                    {
                      staticClass: "mx-4",
                      class: _vm.$vuetify.breakpoint.smAndDown ? "mb-16" : "",
                      attrs: { "no-gutters": "", justify: "center" },
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.membersToAddList,
                          "menu-props": { bottom: true, offsetY: true },
                          label: _vm.$t("membersToAdd"),
                          outlined: "",
                          dense: "",
                          required: "",
                          multiple: "",
                          "item-text": "mbcode_name",
                          "item-value": "mbcode",
                          "search-input": _vm.searchCountyMembers,
                        },
                        on: {
                          input: function ($event) {
                            _vm.searchCountyMembers = null
                          },
                          "update:searchInput": function ($event) {
                            _vm.searchCountyMembers = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.searchCountyMembers = $event
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend-item",
                              fn: function () {
                                return [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-inner-icon": "mdi-magnify",
                                          label: _vm.$t("searchMembersToAdd"),
                                          "single-line": "",
                                          "hide-details": "",
                                          flat: "",
                                          dense: "",
                                          variant: "underlined",
                                          "clear-icon": "mdi-close-circle",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.searchCountyMembers,
                                          callback: function ($$v) {
                                            _vm.searchCountyMembers = $$v
                                          },
                                          expression: "searchCountyMembers",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "selection",
                              fn: function (ref) {
                                var attrs = ref.attrs
                                var item = ref.item
                                var parent = ref.parent
                                var selected = ref.selected
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._b(
                                      {
                                        attrs: {
                                          size: "small",
                                          density: "compact",
                                          "input-value": selected,
                                        },
                                      },
                                      "v-chip",
                                      attrs,
                                      false
                                    ),
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "pr-2 chip-content" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.mbcode_name) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return parent.selectItem(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-close-circle ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2480095985
                        ),
                        model: {
                          value: _vm.selectedMembersToAdd,
                          callback: function ($$v) {
                            _vm.selectedMembersToAdd = $$v
                          },
                          expression: "selectedMembersToAdd",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mb-2",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      required: "",
                      rows: "4",
                      label: _vm.$t("remarks"),
                    },
                    model: {
                      value: _vm.remarks,
                      callback: function ($$v) {
                        _vm.remarks = $$v
                      },
                      expression: "remarks",
                    },
                  }),
                ],
                1
              ),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1)
                : _vm._e(),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none rounded-lg elevation-0",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            depressed: "",
                            width: "90",
                          },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "primary text-none rounded-lg elevation-0",
                          attrs: {
                            width: "90",
                            depressed: "",
                            disabled:
                              _vm.$v.$invalid || _vm.submitButtonClicked,
                          },
                          on: { click: _vm.hourNoticeSubmit },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-bottom-navigation",
                    {
                      staticClass: "px-2 py-4",
                      attrs: { fixed: "", horizontal: "", height: "70" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: { justify: "space-between", "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "mr-2 text-none rounded-lg elevation-0",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                width: "50%",
                              },
                              on: { click: _vm.closeDialog },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none font-weight-regular grey--text rounded-lg elevation-0",
                              attrs: {
                                color: "primary",
                                width: "50%",
                                depressed: "",
                                disabled:
                                  _vm.$v.$invalid || _vm.submitButtonClicked,
                              },
                              on: { click: _vm.hourNoticeSubmit },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "white--text text-none" },
                                [_vm._v(_vm._s(_vm.$t("submit")))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }